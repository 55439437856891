<template>
  <div id="lh-table">
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :hiddenLang="hiddenLang"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-house-model-create"
    />
    <custom-table 
      ref="table" 
      :max-items="itemsPerPage" 
      :data="items"
      :searchKeys="searchKeys"
      pagination 
      search>
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <AddNewContent
          :allowLangs="['th']"
          :show-popup="false"
          name="property-plan-home-model-create"
          button-label="เพิ่มข้อมูลแบบบ้าน"
        />
        <!-- ITEMS PER PAGE -->
        <LhDropdownPerPage 
          :items="items" 
          :items-per-page="itemsPerPage" 
          :current-page="currentPage" 
          :queried-items="queriedItems"
          @click="(page) => itemsPerPage = page"
        /> 
      </div>
      <template slot="thead">
        <vs-th sort-key="id">รหัสบ้าน</vs-th>
        <vs-th sort-key="house_serie_title">ชื่อแบบบ้าน</vs-th>
        <vs-th sort-key="house_serie_description">สไตล์</vs-th>
        <vs-th sort-key="status">การแสดงผลบนเว็บ</vs-th>
        <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            v-for="(tr, indextr) in data" 
            :data="tr" 
            :key="indextr">
            <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ tr.house_model_code }}</td>
            <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ tr.house_model_title }}</td>
            <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ tr.house_serie_title }}</td>
            <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ tr.status === 'published' ? 'แสดง' : 'ไม่แสดง' }}</td>
            <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ updatedAtDatetimeFormat(tr.updated_at) }}</td>
            <vs-td class="whitespace-no-wrap cursor-default">
              <feather-icon
                class="cursor-pointer"
                icon="CopyIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="duplicateContent(tr)"
              />
              <router-link
                :to="{ name: `${routePrefix}-edit`, params: { id: tr.uuid }}"
                class="pt-2 color-text-link"
              >
                <feather-icon
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </router-link>
              <feather-icon
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2 cursor-pointer"
                @click.stop="deleteData(tr.uuid)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>
<script>
import { computed, ref, onMounted } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useHouseModelUtil from '@/use/useHouseModelUtil'
import useDatepicker from '@/use/useDatepicker'
import map from 'lodash/map'
import omit from 'lodash/omit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import useNotify from '@/use/useNotify'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'HomeModelListView',
  components: {
    AddNewContent,
    PopupLanguage,
    LhDropdownPerPage,
    CustomTable
  },
  setup: (props, ctx) => {
    const crudFunction = useCrud(ctx, 'houseModel')
    const { updatedAtDatetimeFormat } = useDatepicker()
    crudFunction.routePrefix.value = 'property-plan-home-model'

    const {
      error: notifyError,
      warning: notifyWarning
    } = useNotify(ctx)

    const hiddenLang = ref([])
    const allItems = ref([])

    const {
      formData,
      prepareFormData,
      serializeFormData,
      getHouseStyleList,
      getPropertyListCategory,
      getHouseFunctionList,
    } = useHouseModelUtil(ctx)

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => ({
        ...item,
      }))
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      const oldLang = ctx.root.$store.state.locale.currentLang
      const oldHouseSerieList = await getHouseStyleList()

      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)

      const newHouseSerieList = await getHouseStyleList()

      const result = await ctx.root.$store.dispatch(
        'houseModel/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result, oldLang)

      // check serie exist
      const oldHouseSerie = oldHouseSerieList.find((serie) => serie.id === formData.house_style_id)
      const styleTitle = oldHouseSerie ? oldHouseSerie.house_serie_title : ''

      const serieExist = newHouseSerieList.find((serie) => serie.house_serie_title === styleTitle)
      
      if (!serieExist) {
        if (isEmpty(newHouseSerieList)) {
          // await ctx.root.$store.dispatch('locale/changeLang', oldLang)
          notifyError({ text: 'ไม่มีสไตล์ในภาษาที่เลือก กรุณาสร้างสไตล์บ้านและลองใหม่อีกครั้ง' })
          return ctx.root.$vs.loading.close()
        }
        formData.house_style_id = newHouseSerieList[0].id
      } else {
        formData.house_style_id = serieExist.id
      }

      // check serie exist
      const propertyCategories = await getPropertyListCategory()
      const isCategoryExist = propertyCategories.some((cat) => cat.id === formData.property_category_id)
      if (!isCategoryExist) formData.property_category_id = propertyCategories[0].id

      const functionsIds = Object.keys(formData.house_model_functions)

      const houseFunctionList = await getHouseFunctionList()

      functionsIds.forEach((functionId) => {
        const isFunctionExist = houseFunctionList.some((f) => f.id === parseInt(functionId))
        if (!isFunctionExist) delete formData.house_model_functions[functionId]
      })
      
      const duplicateData = omit(formData, ['uuid', 'id', 'revision_id'])
      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      // add user created by
      duplicateData.created_by = email
      duplicateData.updated_by = email

      const duplicateUuid = await ctx.root.$store.dispatch('houseModel/duplicateItem', serializeFormData(duplicateData, langToDuplicationContent))
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      if (!serieExist || !isCategoryExist) {
        const oldLangTranslate = crudFunction.translateLang[oldLang]
        const newLangTranslate = crudFunction.translateLang[langToDuplicationContent]
        const missingValue = !serieExist ? 'สไตล์บ้าน' : 'ประเภทบ้าน'
        notifyWarning({ text: `กรุณาตรวจสอบข้อมูล${missingValue} เนื่องจาก${missingValue}จาก${oldLangTranslate}ไม่มีใน${newLangTranslate}` })
      }
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: duplicateUuid
        },
      })
      
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    onMounted(async () => {
      allItems.value = await ctx.root.$store.dispatch(
        'houseModel/fetchAllLang',
      )
    })

    const duplicateContent = async (data) => {
      const existLang = {}

      allItems.value.forEach((value) => {
        const langValue = value.lang
        const translates = value.house_model_translates
        const titleObj = translates.find((item) => item.attribute_code === 'house_model_title')
        
        if (titleObj.value === data.house_model_title) existLang[langValue] = 1
      })

      const keyLang = Object.keys(existLang)
      hiddenLang.value = !isEmpty(keyLang) ? keyLang : []
      crudFunction.duplicateContent(data.uuid)
    }

    return {
      ...crudFunction,
      items,
      duplicateContent,
      hiddenLang,
      updatedAtDatetimeFormat,
      handlerDuplicateContent,
      searchKeys: ['house_model_code', 'house_model_title', 'house_serie_title', 'status']
    }
  },
}
</script>
